.App {
  text-align: center;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s;
  }
}

.App-header {
  background-color: #eeeeee;
  min-height: 82vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(72000deg);
  }
}

@-webkit-keyframes updown-slide {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0px;
    top: 20px;
  }
}

@keyframes updown-slide {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0px;
    top: 20px;
  }
}

#slide {
  -webkit-animation: updown-slide 3s;
  animation: updown-slide infinite 1s alternate;
  width: 20%;
  height:20%;
  position: relative;
}